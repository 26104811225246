<template>
    <div>
        <explain></explain>
        <feature></feature>
        <!-- 特色 -->
        <section>
            <div class="trait">软件特色</div>
            <div><img style="width: 80px;margin: 20px;" src="../../assets/mobile/logo.png" alt=""></div>
            <div><img src="../../assets/mobile/buttom.png" @click="contactChange('https://version.linglongkeji.im/lastVerson/Linglong_setup.exe')" alt=""></div>
        </section>
        
        <section>
            <div class="contact">联系我们</div>
            <div class="contact_box">
                <div class="contact_content" style="" @click="contactChange('https://t.me/linglongkeji')">
                    <span class="content_text">Telegram: linglongkeji</span>
                </div>
                <!-- <div class="contact_content" style="margin-top:18px" @click="contactChange('https://t.me/yinliu101')">
                    <span class="content_text">Telegram: @yinliu101</span>
                </div> -->
            </div>
        </section>
    </div>
</template>
<script>
import explain from'../../components/mobile/explain.vue'
import feature from'../../components/mobile/feature.vue'
export default {
    components:{
        explain,
        feature
    },
    data: {},
    methods:{
        contactChange(url){
            window.open(url)
        }
    }
}
</script>
<style scoped>
    .trait{
        font-size: 64px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #D3F1FF;
        line-height: 88px;
        margin: 57px 0 22px;
    }
    .contact{
        font-size: 16px;
        font-family: FZDHTK--GBK1-0, FZDHTK--GBK1;
        font-weight: bold;
        color: #0D273C;
        line-height: 32px;
        margin:50px 0 20px
    }
    .contact_box{
        background:url('../../assets/mobile/contact.png') no-repeat;
        width: 345px;
        height: 110px;
        background-size: 100% 100%;
        margin: 20px auto;
        padding: 35px 0;
    }
    .contact_content{
        background:url('../../assets/mobile/contact01.png') no-repeat;
        width: 267px;
        height: 42px;
        background-size: 100% 100%;
        margin: 0 auto;
        text-align: center;
    }
    .content_text{
        font-size: 11px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #6D7795;
        line-height: 42px;
        padding-left: 20px;
    }
</style>